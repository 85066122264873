@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Signika:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css);
body {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  font-size: 11pt;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

#snap-midtrans{
  position: fixed !important;
}
iframe{
  position: unset !important;
}

/* *{
  font-family: 'Abril Fatface', cursive;
font-family: 'Signika', sans-serif;
} */

a{
  text-decoration: none;
  color:inherit;
}

a:hover{
  color:inherit;
}

button:focus-visible{
  border: 1.5px solid #2EBDED;
  box-shadow: 0px 0px 0px 4px rgba(100, 210, 247, 0.1);
}

button:focus{
outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus.btn{
  outline: none !important;
  box-shadow: none !important;
}

.form-control{
  border-radius:0 !important;
}

:focus.form-control{
  box-shadow: none !important;
}

/* zoommm ================= */

img[data-action="zoom"] {
	cursor: zoom-in;
}
.zoom-img,
.zoom-img-wrap {
	position: relative;
	z-index: 666;
	transition: all 300ms;
}
img.zoom-img {
	cursor: zoom-out;
}
.zoom-overlay {
	cursor: zoom-out;
	z-index: 420;
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	filter: "alpha(opacity=0)";
	opacity: 0;
	transition: opacity 300ms;
}
.zoom-overlay-open .zoom-overlay {
	filter: "alpha(opacity=100)";
	opacity: 1;
}

/* zoommm ================= */


/* DISPLAY TAG ========== */
.tag{
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  color: #4a4a4a;
  display: inline-flex;
  font-size: .875rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: break-spaces;
}

.tag.is-success{
  background-color: #2dbded;
  color: #fff;
  background: linear-gradient(270deg, #2dbded, #6897d0, #2e3894);
  background-size: 300% 100%;
  -webkit-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  padding:5px 7px;
  border-radius:5px;
}

.tag.is-danger{
  background-color: #ed2d5a;
  color: #fff;
}

.button.is-primary {
  background-color: #2dbded;
  border-color: transparent !important;
  color: #fff;
}


/* PAGINATION ==================== */

#pressRealesePagination .pagination a {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px !important;
  border: 1px solid #8898AA;
  color: #535861 !important;
  text-decoration: none;
  margin: 0px 2px;
  font-weight: 600;
}

#pressRealesePagination .pagination__link--active a {
  background-color: transparent !important;
  color: #3B3E98;
  border: 1px solid #3B3E98;
}

#pressRealesePagination .pagination__link--disabled a {
  color: #C8C9CC;
  border: 1px solid #83878D;
  background: #83878D;
}




/* GENERAL ============= */

.pagination {
  display: flex;
  padding: 0px 20px;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  /* padding: 5px 8px; */
  display: flex;
  width: 30px;
  height: 30px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
  border: 1px solid #8898AA;
  color: #8898AA !important;
  text-decoration: none;
  margin: 0px 2px;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff !important;
  background: #6c7ac9;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}




/* TABSSSSS================= */

.tabs{
  text-align: center;
  justify-content: center;
  align-items: center;
}

.tab-header {
  list-style: none;
  /* background: #fff; */
  /* border-radius: 36px; */
  /* line-height: 25px; */
  display: flex;
  padding: 0;
  /* justify-content: center;
  align-items: center; */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}
.tab-header li {
  display: flex;
  padding: 7px 12px;
  /* border-radius: 36px; */
  /* line-height: 35px; */
  /* background: red; */
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  border:1px solid #ccc;
}

.tab-header li:first-child{
  border-radius: 36px 0px 0px 36px;
}
.tab-header li:last-child{
  border-radius: 0px 36px 36px 0px;
}

.tab-header li.active,
.tab-header li:hover {
  color:#fff;
  background: #4cb8c4; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4cb8c4,
    #3cd3ad
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


/* TABSS BOOTSTRAP */

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: transparent;
  border:none;
  border-bottom: 2px solid #4cb8c4;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: none;
  font-weight:600;
  color:#495057;
  /* border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; */
}

.table-fixed tbody {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
  float: left;
  position: relative;

  /* ::after {
    content: '';
    clear: both;
    display: block;
  } */
}



.carousel-indicators .active {
    opacity: 1;
}


.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 100px !important;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #3B3E98;
  background-clip: padding-box;
  border: 0;
  /* border-top: 10px solid transparent; */
  /* border-bottom: 10px solid transparent; */
  /* opacity: .5; */
  transition: opacity .6s ease;
}
.wrapper-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999999999999;
  background-color: rgba(0, 0, 0, .4);
}

.container-loader {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: auto;
  -webkit-filter: url('#goo');
          filter: url('#goo');
  -webkit-animation: rotate-move 2s ease-in-out infinite;
          animation: rotate-move 2s ease-in-out infinite;
}

.dot { 
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.dot-3 {
  background-color: #3b3d98;
  -webkit-animation: dot-3-move 2s ease infinite, index 6s ease infinite;
          animation: dot-3-move 2s ease infinite, index 6s ease infinite;
}

.dot-2 {
  background-color: #2abfef;
  -webkit-animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite;
          animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite;
}

.dot-1 {
  background-color: #6899d1;
  -webkit-animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite;
          animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite;
}

@-webkit-keyframes dot-3-move {
  20% {-webkit-transform: scale(1);transform: scale(1)}
  45% {-webkit-transform: translateY(-18px) scale(.45);transform: translateY(-18px) scale(.45)}
  60% {-webkit-transform: translateY(-90px) scale(.45);transform: translateY(-90px) scale(.45)}
  80% {-webkit-transform: translateY(-90px) scale(.45);transform: translateY(-90px) scale(.45)}
  100% {-webkit-transform: translateY(0px) scale(1);transform: translateY(0px) scale(1)}
}

@keyframes dot-3-move {
  20% {-webkit-transform: scale(1);transform: scale(1)}
  45% {-webkit-transform: translateY(-18px) scale(.45);transform: translateY(-18px) scale(.45)}
  60% {-webkit-transform: translateY(-90px) scale(.45);transform: translateY(-90px) scale(.45)}
  80% {-webkit-transform: translateY(-90px) scale(.45);transform: translateY(-90px) scale(.45)}
  100% {-webkit-transform: translateY(0px) scale(1);transform: translateY(0px) scale(1)}
}

@-webkit-keyframes dot-2-move {
  20% {-webkit-transform: scale(1);transform: scale(1)}
  45% {-webkit-transform: translate(-16px, 12px) scale(.45);transform: translate(-16px, 12px) scale(.45)}
  60% {-webkit-transform: translate(-80px, 60px) scale(.45);transform: translate(-80px, 60px) scale(.45)}
  80% {-webkit-transform: translate(-80px, 60px) scale(.45);transform: translate(-80px, 60px) scale(.45)}
  100% {-webkit-transform: translateY(0px) scale(1);transform: translateY(0px) scale(1)}
}

@keyframes dot-2-move {
  20% {-webkit-transform: scale(1);transform: scale(1)}
  45% {-webkit-transform: translate(-16px, 12px) scale(.45);transform: translate(-16px, 12px) scale(.45)}
  60% {-webkit-transform: translate(-80px, 60px) scale(.45);transform: translate(-80px, 60px) scale(.45)}
  80% {-webkit-transform: translate(-80px, 60px) scale(.45);transform: translate(-80px, 60px) scale(.45)}
  100% {-webkit-transform: translateY(0px) scale(1);transform: translateY(0px) scale(1)}
}

@-webkit-keyframes dot-1-move {
  20% {-webkit-transform: scale(1);transform: scale(1)}
  45% {-webkit-transform: translate(16px, 12px) scale(.45);transform: translate(16px, 12px) scale(.45)}
  60% {-webkit-transform: translate(80px, 60px) scale(.45);transform: translate(80px, 60px) scale(.45)}
  80% {-webkit-transform: translate(80px, 60px) scale(.45);transform: translate(80px, 60px) scale(.45)}
  100% {-webkit-transform: translateY(0px) scale(1);transform: translateY(0px) scale(1)}
}

@keyframes dot-1-move {
  20% {-webkit-transform: scale(1);transform: scale(1)}
  45% {-webkit-transform: translate(16px, 12px) scale(.45);transform: translate(16px, 12px) scale(.45)}
  60% {-webkit-transform: translate(80px, 60px) scale(.45);transform: translate(80px, 60px) scale(.45)}
  80% {-webkit-transform: translate(80px, 60px) scale(.45);transform: translate(80px, 60px) scale(.45)}
  100% {-webkit-transform: translateY(0px) scale(1);transform: translateY(0px) scale(1)}
}

@-webkit-keyframes rotate-move {
  55% {-webkit-transform: translate(-50%, -50%) rotate(0deg);transform: translate(-50%, -50%) rotate(0deg)}
  80% {-webkit-transform: translate(-50%, -50%) rotate(360deg);transform: translate(-50%, -50%) rotate(360deg)}
  100% {-webkit-transform: translate(-50%, -50%) rotate(360deg);transform: translate(-50%, -50%) rotate(360deg)}
}

@keyframes rotate-move {
  55% {-webkit-transform: translate(-50%, -50%) rotate(0deg);transform: translate(-50%, -50%) rotate(0deg)}
  80% {-webkit-transform: translate(-50%, -50%) rotate(360deg);transform: translate(-50%, -50%) rotate(360deg)}
  100% {-webkit-transform: translate(-50%, -50%) rotate(360deg);transform: translate(-50%, -50%) rotate(360deg)}
}

@-webkit-keyframes index {
  0%, 100% {z-index: 3}
  33.3% {z-index: 2}
  66.6% {z-index: 1}
}

@keyframes index {
  0%, 100% {z-index: 3}
  33.3% {z-index: 2}
  66.6% {z-index: 1}
}


#container-tabs .nav-tabs {
  border-bottom: none !important;
  justify-content: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

}

#container-tabs .nav-tabs .nav-link{
  background-color: #FFFFFF;
  width: 190px;
  height:60px;
  margin-bottom: -1px;
  position: relative;
  text-transform: uppercase;
  color:#3B3E98;
  
}

#container-tabs .nav-tabs .nav-link.active::after{
    bottom:-20px;
    right: 80px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #3B3E98;
    border-width: 10px;
    margin-left: -10px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

 /* .nav-tabs  .nav-link:nth-child(1){
  border-radius: 6px 0px 0px 6px;

}
 .nav-tabs  .nav-link:nth-child(2){
  border-radius: 6px 6px 6px 6px !important;

} */


#container-tabs .nav-tabs .nav-link.active {

  background-color: #3B3E98;
  color: #FFFFFF;
  border-bottom: none !important;
}
.slide-container{
  max-width: 1120px;
  width: 100%;
  padding: 40px 0;
}

.slide-container > .arrow-container{
  margin-top:58px;
}
.slide-content{
  margin: 0 40px;
  overflow: hidden;
  border-radius: 25px;
}
.card{
  border-radius: 25px;
}
.image-content,
.card-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 14px;
}
.image-content{
  position: relative;
  grid-row-gap: 5px;
  row-gap: 5px;
  padding: 25px 0;
}
.overlay{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 25px 25px 0 25px;
}
.overlay::before,
.overlay::after{
  content: '';
  position: absolute;
  right: 0;
  bottom: -40px;
  height: 40px;
  width: 40px;
  background-color: #4070F4;
}
.overlay::after{
  border-radius: 0 25px 0 0;
  /* background-color: #FFF; */
}
.card-image{
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  /* background: #FFF; */
  padding: 3px;
}
.card-image .card-img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #4070F4;
}
.name{
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
.description{
  font-size: 14px;
  color: #707070;
  text-align: center;
}
.button{
  border: none;
  font-size: 16px;
  color: #FFF;
  padding: 8px 16px;
  background-color: #4070F4;
  border-radius: 6px;
  margin: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button:hover{
  background: #265DF2;
}

.swiper-navBtn{
  /* color: #6E93f7; */
  transition: color 0.3s ease;
}
/* .swiper-navBtn:hover{
  color: #4070F4;
}
.swiper-navBtn::before,
.swiper-navBtn::after{
  font-size: 35px;
} */

.swiper-button-next {
  position: unset !important;
  /* top: 50%; */
  /* width: calc(var(--swiper-navigation-size)/ 44 * 27); */
  /* height: var(--swiper-navigation-size); */
  /* margin-top: calc(0px - (var(--swiper-navigation-size)/ 2)); */
  /* z-index: 10; */
  /* cursor: pointer; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: black;
}
.swiper-button-prev {
  
  position: unset !important;
  top: 50%;
  margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-theme-color);
  color: var(--swiper-navigation-color,var(--swiper-theme-color));
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: auto !important;
  right: auto;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: '' !important;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: '' !important;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-family: poppins !important;
  text-transform: none!important;
  letter-spacing: 0;
  -webkit-font-feature-settings: ;
          font-feature-settings: ;
  font-variant: initial;
  line-height: 1;
}
.swiper-button-next{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  width:53px !important;
  height: 53px !important;
  border-radius:100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.swiper-button-prev img{
  
  -webkit-transform: rotate(180deg);
  
          transform: rotate(180deg);
}
.swiper-button-prev{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  width:53px !important;
  height: 53px !important;
  border-radius:100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.swiper-button-prev1 > img{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.swiper-pagination-bullet{
  background-color: #6E93f7;
  opacity: 1;
}
.swiper-pagination-bullet-active{
  background-color: #4070F4;
}

@media screen and (max-width: 768px) {
  .slide-content{
    margin: 0 10px;
  }
  .swiper-navBtn{
    display: none;
  }
}

.button-secondary{height:38px;overflow:hidden;background:#6f72b2;border-radius:4px;font-family:"Mulish";font-style:normal;font-weight:700;font-size:12px;line-height:23px;display:flex;align-items:center;text-align:center;justify-content:center;color:#fff}.button-primary{height:38px;overflow:hidden;background:#3b3e98;border-radius:4px;font-family:"Mulish";font-style:normal;font-weight:700;font-size:12px;line-height:23px;display:flex;align-items:center;text-align:center;justify-content:center;color:#fff}.button-green{height:38px;overflow:hidden;background:#78f244;border-radius:4px;font-family:"Mulish";font-style:normal;font-weight:700;font-size:12px;line-height:23px;display:flex;align-items:center;text-align:center;justify-content:center;color:#fff}.button-green:hover{color:#fff}.button-secondary:hover{color:#fff}.button-primary:hover{color:#fff}.button-auth-google{width:100%;justify-content:center}
@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.6ab7f6a9.eot);
  src: url(/static/media/fontello.6ab7f6a9.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.25f5e4f1.woff2) format('woff2'),
       url(/static/media/fontello.3beea5e7.woff) format('woff'),
       url(/static/media/fontello.03b159b7.ttf) format('truetype'),
       url(/static/media/fontello.5c798fe3.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?4981291#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-activity:before { content: '\e800'; } /* '' */
.icon-advertising:before { content: '\e801'; } /* '' */
.icon-airplay:before { content: '\e802'; } /* '' */
.icon-alert_circle:before { content: '\e803'; } /* '' */
.icon-alert_octagon:before { content: '\e804'; } /* '' */
.icon-alert_triangle:before { content: '\e805'; } /* '' */
.icon-align_center:before { content: '\e806'; } /* '' */
.icon-align-justify:before { content: '\e807'; } /* '' */
.icon-align-left:before { content: '\e808'; } /* '' */
.icon-align-right:before { content: '\e809'; } /* '' */
.icon-anchor:before { content: '\e80a'; } /* '' */
.icon-aperture:before { content: '\e80b'; } /* '' */
.icon-archive:before { content: '\e80c'; } /* '' */
.icon-arrow-down:before { content: '\e80d'; } /* '' */
.icon-arrow-down-circle:before { content: '\e80e'; } /* '' */
.icon-arrow-down-left:before { content: '\e80f'; } /* '' */
.icon-arrow-down-right:before { content: '\e810'; } /* '' */
.icon-arrow-left:before { content: '\e811'; } /* '' */
.icon-arrow-left-circle:before { content: '\e812'; } /* '' */
.icon-arrow-right:before { content: '\e813'; } /* '' */
.icon-arrow-right-circle:before { content: '\e814'; } /* '' */
.icon-arrow-up:before { content: '\e815'; } /* '' */
.icon-arrow-up-circle:before { content: '\e816'; } /* '' */
.icon-arrow-up-left:before { content: '\e817'; } /* '' */
.icon-arrow-up-right:before { content: '\e818'; } /* '' */
.icon-at-sign:before { content: '\e819'; } /* '' */
.icon-award:before { content: '\e81a'; } /* '' */
.icon-bar-chart:before { content: '\e81b'; } /* '' */
.icon-bar-chart-2:before { content: '\e81c'; } /* '' */
.icon-battery:before { content: '\e81d'; } /* '' */
.icon-battery-charging:before { content: '\e81e'; } /* '' */
.icon-bell:before { content: '\e81f'; } /* '' */
.icon-bell-off:before { content: '\e820'; } /* '' */
.icon-bluetooth:before { content: '\e821'; } /* '' */
.icon-bold:before { content: '\e822'; } /* '' */
.icon-book:before { content: '\e823'; } /* '' */
.icon-bookmark:before { content: '\e824'; } /* '' */
.icon-book-open:before { content: '\e825'; } /* '' */
.icon-box:before { content: '\e826'; } /* '' */
.icon-briefcase:before { content: '\e827'; } /* '' */
.icon-calendar:before { content: '\e828'; } /* '' */
.icon-camera:before { content: '\e829'; } /* '' */
.icon-camera-off:before { content: '\e82a'; } /* '' */
.icon-cast:before { content: '\e82b'; } /* '' */
.icon-check:before { content: '\e82c'; } /* '' */
.icon-check-circle:before { content: '\e82d'; } /* '' */
.icon-check-square:before { content: '\e82e'; } /* '' */
.icon-chevron-down:before { content: '\e82f'; } /* '' */
.icon-chevron-left:before { content: '\e830'; } /* '' */
.icon-chevron-right:before { content: '\e831'; } /* '' */
.icon-chevrons-down:before { content: '\e832'; } /* '' */
.icon-chevrons-left:before { content: '\e833'; } /* '' */
.icon-chevrons-right:before { content: '\e834'; } /* '' */
.icon-chevrons-up:before { content: '\e835'; } /* '' */
.icon-chevron-up:before { content: '\e836'; } /* '' */
.icon-chrome:before { content: '\e837'; } /* '' */
.icon-circle:before { content: '\e838'; } /* '' */
.icon-clipboard:before { content: '\e839'; } /* '' */
.icon-clock:before { content: '\e83a'; } /* '' */
.icon-cloud:before { content: '\e83b'; } /* '' */
.icon-cloud-lightning:before { content: '\e83c'; } /* '' */
.icon-cloud-drizzle:before { content: '\e83d'; } /* '' */
.icon-cloud-off:before { content: '\e83e'; } /* '' */
.icon-cloud-rain:before { content: '\e83f'; } /* '' */
.icon-cloud-snow:before { content: '\e840'; } /* '' */
.icon-code:before { content: '\e841'; } /* '' */
.icon-codepen:before { content: '\e842'; } /* '' */
.icon-codesandbox:before { content: '\e843'; } /* '' */
.icon-coffee:before { content: '\e844'; } /* '' */
.icon-columns:before { content: '\e845'; } /* '' */
.icon-command:before { content: '\e846'; } /* '' */
.icon-companies:before { content: '\e847'; } /* '' */
.icon-compass:before { content: '\e848'; } /* '' */
.icon-copy:before { content: '\e849'; } /* '' */
.icon-corner-down-left:before { content: '\e84a'; } /* '' */
.icon-corner-down-right:before { content: '\e84b'; } /* '' */
.icon-corner-left-down:before { content: '\e84c'; } /* '' */
.icon-corner-left-up:before { content: '\e84d'; } /* '' */
.icon-corner-right-down:before { content: '\e84e'; } /* '' */
.icon-corner-right-up:before { content: '\e84f'; } /* '' */
.icon-corner-up-left:before { content: '\e850'; } /* '' */
.icon-corner-up-right:before { content: '\e851'; } /* '' */
.icon-cpu:before { content: '\e852'; } /* '' */
.icon-create:before { content: '\e853'; } /* '' */
.icon-credit-card:before { content: '\e854'; } /* '' */
.icon-crop:before { content: '\e855'; } /* '' */
.icon-crosshair:before { content: '\e856'; } /* '' */
.icon-cs:before { content: '\e857'; } /* '' */
.icon-database:before { content: '\e858'; } /* '' */
.icon-delete:before { content: '\e859'; } /* '' */
.icon-disc:before { content: '\e85a'; } /* '' */
.icon-divide:before { content: '\e85b'; } /* '' */
.icon-divide-circle:before { content: '\e85c'; } /* '' */
.icon-divide-square:before { content: '\e85d'; } /* '' */
.icon-dollar-sign:before { content: '\e85e'; } /* '' */
.icon-download:before { content: '\e85f'; } /* '' */
.icon-download-cloud:before { content: '\e860'; } /* '' */
.icon-dribbble:before { content: '\e861'; } /* '' */
.icon-droplet:before { content: '\e862'; } /* '' */
.icon-edit:before { content: '\e863'; } /* '' */
.icon-edit-2:before { content: '\e864'; } /* '' */
.icon-edit-3:before { content: '\e865'; } /* '' */
.icon-event:before { content: '\e866'; } /* '' */
.icon-external-link:before { content: '\e867'; } /* '' */
.icon-eye:before { content: '\e868'; } /* '' */
.icon-eye-off:before { content: '\e869'; } /* '' */
.icon-facebook:before { content: '\e86a'; } /* '' */
.icon-fast-forward:before { content: '\e86b'; } /* '' */
.icon-feather:before { content: '\e86c'; } /* '' */
.icon-figma:before { content: '\e86d'; } /* '' */
.icon-file:before { content: '\e86e'; } /* '' */
.icon-file-minus:before { content: '\e86f'; } /* '' */
.icon-file-plus:before { content: '\e870'; } /* '' */
.icon-file-text:before { content: '\e871'; } /* '' */
.icon-film:before { content: '\e872'; } /* '' */
.icon-filter:before { content: '\e873'; } /* '' */
.icon-flag:before { content: '\e874'; } /* '' */
.icon-folder:before { content: '\e875'; } /* '' */
.icon-folder-minus:before { content: '\e876'; } /* '' */
.icon-folder-plus:before { content: '\e877'; } /* '' */
.icon-framer:before { content: '\e878'; } /* '' */
.icon-frown:before { content: '\e879'; } /* '' */
.icon-gift:before { content: '\e87a'; } /* '' */
.icon-git-branch:before { content: '\e87b'; } /* '' */
.icon-git-commit:before { content: '\e87c'; } /* '' */
.icon-github:before { content: '\e87d'; } /* '' */
.icon-gitlab:before { content: '\e87e'; } /* '' */
.icon-git-merge:before { content: '\e87f'; } /* '' */
.icon-git-pull-request:before { content: '\e880'; } /* '' */
.icon-globe:before { content: '\e881'; } /* '' */
.icon-grid:before { content: '\e882'; } /* '' */
.icon-hard-drive:before { content: '\e883'; } /* '' */
.icon-hash:before { content: '\e884'; } /* '' */
.icon-headphones:before { content: '\e885'; } /* '' */
.icon-heart:before { content: '\e886'; } /* '' */
.icon-help-circle:before { content: '\e887'; } /* '' */
.icon-hexagon:before { content: '\e888'; } /* '' */
.icon-home:before { content: '\e889'; } /* '' */
.icon-image:before { content: '\e88a'; } /* '' */
.icon-inbox:before { content: '\e88b'; } /* '' */
.icon-info:before { content: '\e88c'; } /* '' */
.icon-instagram:before { content: '\e88d'; } /* '' */
.icon-italic:before { content: '\e88e'; } /* '' */
.icon-key:before { content: '\e88f'; } /* '' */
.icon-layers:before { content: '\e890'; } /* '' */
.icon-layout:before { content: '\e891'; } /* '' */
.icon-life-buoy:before { content: '\e892'; } /* '' */
.icon-link:before { content: '\e893'; } /* '' */
.icon-link-2:before { content: '\e894'; } /* '' */
.icon-linkedin:before { content: '\e895'; } /* '' */
.icon-list:before { content: '\e896'; } /* '' */
.icon-loader:before { content: '\e897'; } /* '' */
.icon-lock:before { content: '\e898'; } /* '' */
.icon-log-in:before { content: '\e899'; } /* '' */
.icon-logogram_color:before { content: '\e89a'; } /* '' */
.icon-logogram_outline:before { content: '\e89b'; } /* '' */
.icon-log-out:before { content: '\e89c'; } /* '' */
.icon-mail:before { content: '\e89d'; } /* '' */
.icon-map:before { content: '\e89e'; } /* '' */
.icon-map-pin:before { content: '\e89f'; } /* '' */
.icon-maximize:before { content: '\e8a0'; } /* '' */
.icon-maximize-2:before { content: '\e8a1'; } /* '' */
.icon-meh:before { content: '\e8a2'; } /* '' */
.icon-menu:before { content: '\e8a3'; } /* '' */
.icon-message-circle:before { content: '\e8a4'; } /* '' */
.icon-message-square:before { content: '\e8a5'; } /* '' */
.icon-mic:before { content: '\e8a6'; } /* '' */
.icon-mic-off:before { content: '\e8a7'; } /* '' */
.icon-minimize:before { content: '\e8a8'; } /* '' */
.icon-minimize-2:before { content: '\e8a9'; } /* '' */
.icon-minus:before { content: '\e8aa'; } /* '' */
.icon-minus-circle:before { content: '\e8ab'; } /* '' */
.icon-minus-square:before { content: '\e8ac'; } /* '' */
.icon-monitor:before { content: '\e8ad'; } /* '' */
.icon-moon:before { content: '\e8ae'; } /* '' */
.icon-more-horizontal:before { content: '\e8af'; } /* '' */
.icon-more-vertical:before { content: '\e8b0'; } /* '' */
.icon-mouse-pointer:before { content: '\e8b1'; } /* '' */
.icon-move:before { content: '\e8b2'; } /* '' */
.icon-music:before { content: '\e8b3'; } /* '' */
.icon-navigation:before { content: '\e8b4'; } /* '' */
.icon-navigation-2:before { content: '\e8b5'; } /* '' */
.icon-octagon:before { content: '\e8b6'; } /* '' */
.icon-package:before { content: '\e8b7'; } /* '' */
.icon-paperclip:before { content: '\e8b8'; } /* '' */
.icon-pause:before { content: '\e8b9'; } /* '' */
.icon-pause-circle:before { content: '\e8ba'; } /* '' */
.icon-pen-tool:before { content: '\e8bb'; } /* '' */
.icon-percent:before { content: '\e8bc'; } /* '' */
.icon-phone:before { content: '\e8bd'; } /* '' */
.icon-phone-call:before { content: '\e8be'; } /* '' */
.icon-phone-forwarded:before { content: '\e8bf'; } /* '' */
.icon-phone-incoming:before { content: '\e8c0'; } /* '' */
.icon-phone-missed:before { content: '\e8c1'; } /* '' */
.icon-phone-off:before { content: '\e8c2'; } /* '' */
.icon-phone-outgoing:before { content: '\e8c3'; } /* '' */
.icon-pie-chart:before { content: '\e8c4'; } /* '' */
.icon-play:before { content: '\e8c5'; } /* '' */
.icon-play-circle:before { content: '\e8c6'; } /* '' */
.icon-plus:before { content: '\e8c7'; } /* '' */
.icon-plus-circle:before { content: '\e8c8'; } /* '' */
.icon-plus-square:before { content: '\e8c9'; } /* '' */
.icon-pocket:before { content: '\e8ca'; } /* '' */
.icon-portal:before { content: '\e8cb'; } /* '' */
.icon-power:before { content: '\e8cc'; } /* '' */
.icon-printer:before { content: '\e8cd'; } /* '' */
.icon-publisher_ads:before { content: '\e8ce'; } /* '' */
.icon-radio:before { content: '\e8cf'; } /* '' */
.icon-refresh-ccw:before { content: '\e8d0'; } /* '' */
.icon-refresh-cw:before { content: '\e8d1'; } /* '' */
.icon-repeat:before { content: '\e8d2'; } /* '' */
.icon-rewind:before { content: '\e8d3'; } /* '' */
.icon-rotate-ccw:before { content: '\e8d4'; } /* '' */
.icon-rotate-cw:before { content: '\e8d5'; } /* '' */
.icon-rp:before { content: '\e8d6'; } /* '' */
.icon-rss:before { content: '\e8d7'; } /* '' */
.icon-save:before { content: '\e8d8'; } /* '' */
.icon-scissors:before { content: '\e8d9'; } /* '' */
.icon-search:before { content: '\e8da'; } /* '' */
.icon-send:before { content: '\e8db'; } /* '' */
.icon-server:before { content: '\e8dc'; } /* '' */
.icon-settings:before { content: '\e8dd'; } /* '' */
.icon-share:before { content: '\e8de'; } /* '' */
.icon-share-2:before { content: '\e8df'; } /* '' */
.icon-shield:before { content: '\e8e0'; } /* '' */
.icon-shield-off:before { content: '\e8e1'; } /* '' */
.icon-shopping-bag:before { content: '\e8e2'; } /* '' */
.icon-shopping-cart:before { content: '\e8e3'; } /* '' */
.icon-shuffle:before { content: '\e8e4'; } /* '' */
.icon-sidebar:before { content: '\e8e5'; } /* '' */
.icon-skip-back:before { content: '\e8e6'; } /* '' */
.icon-skip-forward:before { content: '\e8e7'; } /* '' */
.icon-slack:before { content: '\e8e8'; } /* '' */
.icon-slash:before { content: '\e8e9'; } /* '' */
.icon-sliders:before { content: '\e8ea'; } /* '' */
.icon-smartphone:before { content: '\e8eb'; } /* '' */
.icon-smile:before { content: '\e8ec'; } /* '' */
.icon-sort_ascending:before { content: '\e8ed'; } /* '' */
.icon-sort_descending:before { content: '\e8ee'; } /* '' */
.icon-speaker:before { content: '\e8ef'; } /* '' */
.icon-square:before { content: '\e8f0'; } /* '' */
.icon-star:before { content: '\e8f1'; } /* '' */
.icon-stop-circle:before { content: '\e8f2'; } /* '' */
.icon-sun:before { content: '\e8f3'; } /* '' */
.icon-sunrise:before { content: '\e8f4'; } /* '' */
.icon-sunset:before { content: '\e8f5'; } /* '' */
.icon-tablet:before { content: '\e8f6'; } /* '' */
.icon-tag:before { content: '\e8f7'; } /* '' */
.icon-target:before { content: '\e8f8'; } /* '' */
.icon-terminal:before { content: '\e8f9'; } /* '' */
.icon-thermometer:before { content: '\e8fa'; } /* '' */
.icon-thumbs-down:before { content: '\e8fb'; } /* '' */
.icon-thumbs-up:before { content: '\e8fc'; } /* '' */
.icon-toggle-left:before { content: '\e8fd'; } /* '' */
.icon-toggle-right:before { content: '\e8fe'; } /* '' */
.icon-tool:before { content: '\e8ff'; } /* '' */
.icon-trash:before { content: '\e900'; } /* '' */
.icon-trash-2:before { content: '\e901'; } /* '' */
.icon-trello:before { content: '\e902'; } /* '' */
.icon-trending-down:before { content: '\e903'; } /* '' */
.icon-trending-up:before { content: '\e904'; } /* '' */
.icon-triangle:before { content: '\e905'; } /* '' */
.icon-truck:before { content: '\e906'; } /* '' */
.icon-tv:before { content: '\e907'; } /* '' */
.icon-twitch:before { content: '\e908'; } /* '' */
.icon-twitter:before { content: '\e909'; } /* '' */
.icon-type:before { content: '\e90a'; } /* '' */
.icon-typing_cursor:before { content: '\e90b'; } /* '' */
.icon-umbrella:before { content: '\e90c'; } /* '' */
.icon-underline:before { content: '\e90d'; } /* '' */
.icon-unlock:before { content: '\e90e'; } /* '' */
.icon-upload:before { content: '\e90f'; } /* '' */
.icon-upload-cloud:before { content: '\e910'; } /* '' */
.icon-user:before { content: '\e911'; } /* '' */
.icon-user-check:before { content: '\e912'; } /* '' */
.icon-user-minus:before { content: '\e913'; } /* '' */
.icon-user-plus:before { content: '\e914'; } /* '' */
.icon-users:before { content: '\e915'; } /* '' */
.icon-user-x:before { content: '\e916'; } /* '' */
.icon-video:before { content: '\e917'; } /* '' */
.icon-video-off:before { content: '\e918'; } /* '' */
.icon-voicemail:before { content: '\e919'; } /* '' */
.icon-volume:before { content: '\e91a'; } /* '' */
.icon-volume-1:before { content: '\e91b'; } /* '' */
.icon-volume-2:before { content: '\e91c'; } /* '' */
.icon-volume-x:before { content: '\e91d'; } /* '' */
.icon-watch:before { content: '\e91e'; } /* '' */
.icon-wifi:before { content: '\e91f'; } /* '' */
.icon-wifi-off:before { content: '\e920'; } /* '' */
.icon-wind:before { content: '\e921'; } /* '' */
.icon-x:before { content: '\e922'; } /* '' */
.icon-x-circle:before { content: '\e923'; } /* '' */
.icon-x-octagon:before { content: '\e924'; } /* '' */
.icon-x-square:before { content: '\e925'; } /* '' */
.icon-youtube:before { content: '\e926'; } /* '' */
.icon-zap:before { content: '\e927'; } /* '' */
.icon-zap-off:before { content: '\e928'; } /* '' */
.icon-zoom-in:before { content: '\e929'; } /* '' */
.icon-zoom-out:before { content: '\e92a'; } /* '' */

